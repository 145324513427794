import { render } from 'react-dom'
import React, { Suspense } from 'react'
import { Canvas } from 'react-three-fiber'
import * as THREE from 'three'

import Scene from './Scene'

function App() {
  return (
    <Canvas
      camera={{ position: [0, 0, 4], fov: 55}}
      onCreated={({ scene }) => {
        scene.background = new THREE.Color('#000000').convertGammaToLinear()
      }}>
      <Suspense fallback={null}>
        <Scene />
      </Suspense>
      <ambientLight intensity={0.4} />
    </Canvas>
  )
}

render(<App />, document.querySelector('#root'))
